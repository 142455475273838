import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import "react-h5-audio-player/lib/styles.css"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { isValidContentfulImage, isValidSectionField } from "../common/utils/CommonUtils"
import "./../pages/home-page.scss"
import "./../pages/learning-library.scss"
import ArrowForwardIcon from "@material-ui/icons/Forward"
import ArrowNavigationIcon from "@material-ui/icons/NearMe"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ServiceAreas from './../components/service-areas';
export default function (props) {

    const [activeFaqIndex, setActiveFaqIndex] = useState(-1)
    const data = props.data.contentfulLocalLandingPage;
    const {serviceSlug, stateSlug, citySlug, disclaimer, whyConfidantHeading, expectationsMainSection, whyConfidantSections, expectationsShortSections, topSection,
        downloadAppText, letterToLovedOnes, faqsHeading, faqLinks,expectationsHeading} = data;
    return (<Layout location={props.location}>

        {
            isValidSectionField(topSection, "title") && <SEO title={topSection.title}/>
        }
        <div className="get-started-home-wrapper">
            <div className="get-started-home-inner">
                <div className="get-started-text-wrap">
                    <img className="get-started-home-pattern"
                         src={require(
                             '../assets/images/home-bg-circles.png')}
                         alt="Pattern"/>
                    {
                        isValidSectionField(topSection, "title") &&
                        <h1 className="blackHeading2">{topSection.title}</h1>
                    }
                    {
                        isValidSectionField(topSection, "description")
                        &&
                        <p className="subTitle"
                           dangerouslySetInnerHTML={{__html: topSection.description.description}}></p>
                    }
                    {
                        isValidSectionField(topSection, "groupExtraDescription")
                        &&
                        <p className="parah"
                           dangerouslySetInnerHTML={{__html: topSection.groupExtraDescription.groupExtraDescription}}></p>
                    }
                    {
                        disclaimer
                        &&
                        <p className="subPara"
                           dangerouslySetInnerHTML={{__html: disclaimer.disclaimer}}></p>
                    }
                    <Button onClick={() => {

                    }}
                            href={"/get-started"}
                            className="blue-arrow-btn" color="primary">
                        Get Started <ArrowForwardIcon/>
                    </Button>
                </div>
                <div className="get-started-anim-wrap">
                    {
                        isValidContentfulImage(topSection.imageOrVideo)
                        && (
                            <img className="get-started-home-hero-img"
                                 src={topSection.imageOrVideo?.file?.url}
                                 alt="providerImg"/>
                        )
                    }
                </div>
            </div>
        </div>
        <div className="new-home-features">

            {
                whyConfidantHeading!==null && <h2 className="interested-heading mx-wd-500">{whyConfidantHeading}</h2>
            }

            <div className="new-home-features-inner">

                {
                    whyConfidantSections && whyConfidantSections.map(
                        (section, index) => (
                            <div key={"key-" + section.heading}
                                 className="new-home-single-feature">
                                {
                                    index % 2 !== 0 && (
                                        <div
                                            className="new-feature-img-side">
                                            <img
                                                className="new-feature-image"
                                                src={section.image.file.url}
                                                alt=" Frame"/>
                                        </div>
                                    )
                                }
                                <div className="new-feature-text-side">
                                    {
                                        isValidSectionField(section,
                                            "heading") &&

                                        <h2 className="feature-heading">
                                            {section.heading}
                                        </h2>
                                    }

                                    {

                                        isValidSectionField(section,
                                            "description") &&

                                        <p className="feature-para"
                                           dangerouslySetInnerHTML={{__html: section.description.description}}></p>
                                    }
                                </div>
                                {
                                    (index === 0 || index % 2 === 0) && (
                                        <div
                                            className="new-feature-img-side"
                                            style={{textAlign: "right"}}>
                                            {isValidContentfulImage(
                                                section.image) && (
                                                <img
                                                    className="new-feature-image"
                                                    src={section.image.file.url}
                                                    alt=" Frame"/>
                                            )}

                                        </div>
                                    )
                                }
                            </div>
                        ))
                }

                {
                    expectationsMainSection && <div className="expectationsWrapper">
                        <div className="expectationsTitle">
                            <h2 className="interested-heading">{expectationsHeading}</h2>
                        </div>
                        <div className="expectationsContent">
                            {isValidContentfulImage(
                                expectationsMainSection.image) && (
                                <img
                                    className="new-feature-image"
                                    src={expectationsMainSection.image.file.url}
                                    alt=" Frame"/>
                            )}
                            <p className="subTitle"
                               dangerouslySetInnerHTML={{__html: expectationsMainSection.name}}></p>
                            <p className="parah"
                               dangerouslySetInnerHTML={{__html: expectationsMainSection.description}}></p>
                        </div>
                    </div>
                }
                <hr className="custom-hr"/>

                {
                    expectationsShortSections && expectationsShortSections.map(
                        (section, index) => (
                            <div key={"key-" + section.heading}
                                 className="new-home-single-feature">
                                {
                                    index % 2 !== 0 && (
                                        <div
                                            className="new-feature-img-side">
                                            <img
                                                className="new-feature-image"
                                                src={section.image.file.url}
                                                alt=" Frame"/>
                                        </div>
                                    )
                                }
                                <div className="new-feature-text-side">
                                    {
                                        isValidSectionField(section,
                                            "heading") &&

                                        <h2 className="feature-heading">
                                            {section.heading}
                                        </h2>
                                    }

                                    {

                                        isValidSectionField(section,
                                            "description") &&

                                        <p className="feature-para"
                                           dangerouslySetInnerHTML={{__html: section.description.description}}></p>
                                    }
                                </div>
                                {
                                    (index === 0 || index % 2 === 0) && (
                                        <div
                                            className="new-feature-img-side"
                                            style={{textAlign: "right"}}>
                                            {isValidContentfulImage(
                                                section.image) && (
                                                <img
                                                    className="new-feature-image"
                                                    src={section.image.file.url}
                                                    alt=" Frame"/>
                                            )}

                                        </div>
                                    )
                                }
                            </div>
                        ))
                }

                {
                    downloadAppText && (
                        <div className="something-main-wrapper">
                            <div className="something-inner">
                                <h2 className="something-head">{downloadAppText.downloadAppText}</h2>
                                <Button className="blue-arrow-btn" type="submit">
                                    DOWNLOAD OUR APP <ArrowNavigationIcon/>
                                </Button>

                            </div>

                        </div>
                    )
                }

                <div className="new-home-interested-wrapper local-page-interested-wrapper">
                    {/*<div className="new-home-interested-pattern"></div>*/}
                    <h2 className="interested-heading">How Can We Help You?</h2>
                    <p className="interested-sub-heading">We know that no two people are the same. That's why Confidant offers a variety of services, such as addiction and substance abuse treatment, to meet your needs.</p>
                    <div className="new-home-interested-inner">
                        <div className="interested-list">
                            <div className="single-interested-box">
                                <img className="new-feature-image"
                                     src={require(
                                         '../assets/images/provider-prescriber.svg')}
                                     alt="Providers & Prescriber"/>
                                <Button href="/online-therapy/"
                                        className="new-blue-link"
                                        color="primary">
                                    Providers &
                                    Prescribers <ArrowForwardThinIcon/>
                                </Button>
                            </div>

                            <div className="single-interested-box">
                                <img className="new-feature-image"
                                     src={require(
                                         '../assets/images/free-support.svg')}
                                     alt="Support Groups"/>
                                <Button href="/support-groups/"
                                        className="new-blue-link"
                                        color="primary">
                                    Support Groups <ArrowForwardThinIcon/>
                                </Button>
                            </div>

                            <div className="single-interested-box">
                                <img className="new-feature-image"
                                     src={require(
                                         '../assets/images/self-directed.svg')}
                                     alt="Self-directed Courses"/>
                                <Button href="/how-to-guides/"
                                        className="new-blue-link"
                                        color="primary">
                                    Self-directed
                                    Courses <ArrowForwardThinIcon/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    letterToLovedOnes &&
                    (
                        <div className="new-home-letter-one">
                            <div className="new-home-letter-one-inner">
                                {isValidContentfulImage(
                                    letterToLovedOnes.image) && (
                                    <img
                                        className="new-feature-image"
                                        src={letterToLovedOnes.image.file.url}
                                        alt=" Frame"/>
                                )}
                                <h2 className="home-letter-one-heading">{letterToLovedOnes.heading}</h2>
                                <p className="home-letter-one-para">{letterToLovedOnes.description.description}</p>
                                <Button href="/letter-to-loved-ones/"
                                        className="new-blue-link" color="primary">
                                    Read the Letter <ArrowForwardThinIcon/>
                                </Button>
                            </div>
                        </div>
                    )

                }

                <div className="faqWrapper">
                    {
                        faqsHeading !== null && <h2 className="interested-heading">{faqsHeading}</h2>
                    }
                    <div className="faqList">
                        {
                            faqLinks && faqLinks.map((faqItem, index) => {
                                const active = index === activeFaqIndex
                                return (
                                    <div className="faqItems" key={"faq-item-" + index}>
                                        <Button
                                            onClick={() => {
                                                setActiveFaqIndex(index)
                                            }}

                                            className={active ? "new-blue-link-active" : "new-blue-link"}
                                            color="primary">
                                            {faqItem.title.title}
                                        </Button>
                                        {
                                            active &&
                                            <div className="parah">
                                                {documentToReactComponents(JSON.parse(faqItem.description.raw))}
                                               </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <ServiceAreas serviceSlug={serviceSlug} stateSlug={stateSlug} citySlug={citySlug}/>



            </div>
        </div>
    </Layout>);
}
export const query = graphql`
query getLandingPageDetails($serviceSlug:String, $stateSlug:String, $citySlug:String)
{
  contentfulLocalLandingPage(serviceSlug: {eq: $serviceSlug}, stateSlug: {eq: $stateSlug}, citySlug: {eq: $citySlug}) {
      serviceSlug
      stateSlug
      citySlug
      topSection {
        title
        imageOrVideo {
          file {
            url
          }
        }
        description {
          description
        }
        groupExtraDescription {
          groupExtraDescription
        }
      }
      disclaimer {
        disclaimer
      }
      whyConfidantHeading
      whyConfidantSections {
        heading
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
      expectationsMainSection {
        name
        description
        image {
          file {
            url
          }
        }
      }
      expectationsShortSections {
        heading
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
      downloadAppText {
        downloadAppText
      }
      letterToLovedOnes {
        description {
          description
        }
        image {
          file {
            url
          }
        }
        heading
      }
      faqsHeading
      faqLinks {
        title {
            title
        }
        description {
            raw
        }
      }
      expectationsHeading
    }
}`;
